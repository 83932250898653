import { Flex, Image, rem, Title, Tooltip } from '@mantine/core';
import * as React from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useCombinedStore } from '../../../stores/combinedStore';
import spotifyLogoWhite from '../../../static/images/Spotify_Logo_RGB_White.png';
import { AudioFeatures } from '../molecules/audioFeatures';
import { TrackInfoSkeleton } from './trackInfo.skeleton';
import classes from './trackInfo.module.css';
import { SpotifyAlbumOrTrackArtist } from '../../../models/spotifyApi.interface';
import { getMusicalKeyString } from '../../../utils/getMusicalKeyString';
import { smallGap } from '../../../constants/constants';
import { iconSize } from '../../../utils/iconSize';

export function TrackInfo() {
  const currentTrack = useCombinedStore((state) => state.currentTrack);

  if (!currentTrack) {
    return <TrackInfoSkeleton />;
  }

  return (
    <div className={classes.trackInfoContainer}>
      <Flex align="center" gap={smallGap}>
        {currentTrack.is_local ? (
          <Tooltip
            label="This is a 'local track', the track was added on your local machine as an audio file to Spotify. You can't copy, move, like or delete it. You can only see the track info."
            withArrow
            transitionProps={{ transition: 'fade', duration: 300 }}
            events={{ hover: true, focus: true, touch: false }}
            openDelay={500}
            closeDelay={100}
            position="bottom"
            radius="md"
            multiline
            w={290}
          >
            <Flex align="center" gap={smallGap}>
              <IconAlertTriangle style={{ ...iconSize(22) }} /> <Title order={3}>LOCAL TRACK</Title>
            </Flex>
          </Tooltip>
        ) : (
          <Title order={3}>{`${currentTrack.track.type.toUpperCase()}`}</Title>
        )}
        <Title order={3} pb={rem(3)}>
          •
        </Title>
        <Image src={spotifyLogoWhite} alt="Spotify Logo" w={90} />
      </Flex>
      <Title order={1} lineClamp={2} className={classes.trackName}>
        {currentTrack.track.name}
      </Title>
      <Title order={2} lineClamp={2} className={classes.artistName}>
        {currentTrack.track.artists
          .map((artist: SpotifyAlbumOrTrackArtist) => artist.name)
          .join(', ')}
      </Title>
      <Title order={4} lineClamp={2} className={classes.albumName}>
        {currentTrack.track.album.name}
      </Title>
      {currentTrack.track.type !== 'episode' && !currentTrack.is_local ? (
        <>
          <p className={classes.heading}>
            {[currentTrack.additionalAlbumInfo?.label, currentTrack.track.album.release_date].join(
              ' • '
            )}
          </p>
          <p className={classes.heading}>{currentTrack.additionalAlbumInfo?.genres?.join(', ')}</p>
          <p className={classes.heading}>
            {`BPM: ${currentTrack.audioFeatures?.tempo.toFixed(1)} • Key: ${getMusicalKeyString(
              currentTrack.audioFeatures?.key,
              currentTrack.audioFeatures?.mode
            )}`}
          </p>
          <AudioFeatures />
        </>
      ) : null}
    </div>
  );
}
