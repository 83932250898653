import * as React from 'react';
import { Button, Container, Flex, Grid, Image, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconAnalyze, IconJetpack, IconPlaylistAdd } from '@tabler/icons-react';
import { useCombinedStore } from '../../../stores/combinedStore';
import spotifyIconWhite from '../../../static/images/Spotify_Icon_RGB_White.svg';
import { AppPaths } from '../../../constants/enums';
import spotifyLogoWhite from '../../../static/images/Spotify_Logo_RGB_White.png';
import classes from './landingPage.module.css';
import { MiniHero } from '../molecules/miniHero';
import { SeoHeading } from './seoHeading';

export function LandingPage() {
  const profile = useCombinedStore((state) => state.userProfile);
  const initiateLogin = useCombinedStore((state) => state.initiateLogin);

  return (
    <Container className={classes.landingContainer} pb="15vh">
      <Flex align="center">
        <Text className={classes.textWhite}>Manage&nbsp;your&nbsp;</Text>
        <Image src={spotifyLogoWhite} className={classes.imageAuto} alt="Spotify Logo" />
      </Flex>
      <Flex align="center">
        <Text className={classes.textWhite} variant="gradient" pb="20px">
          Playlists&nbsp;Pro
        </Text>
        <Text className={classes.textWhite} c="white" pb="20px">
          fessionally
        </Text>
      </Flex>
      <SeoHeading level={2} seoText="Manage your Spotify Playlists Professionally" />
      <Text className={classes.textPad}>
        Manage your Spotify playlists faster than ever. With more insight into your tracks and many
        different organization tools, you can organize your playlists in any way you want.
      </Text>
      <SeoHeading
        level={3}
        seoText="Manage your Spotify playlists faster than ever. With more insight into your tracks and many
        different organization tools, you can organize your playlists in any way you want."
      />
      <Grid>
        <MiniHero
          header="Track Analysis"
          text="Get detailed info on your tracks using Spotify's audio features."
          icon={IconAnalyze}
        />
        <MiniHero
          header="Multiple Playlists"
          text="Move or copy your tracks to multiple playlists at once."
          icon={IconPlaylistAdd}
        />{' '}
        <MiniHero
          header="This is only the beginning"
          text="More features like playlist merging and analytics coming soon!"
          icon={IconJetpack}
        />
      </Grid>
      <SeoHeading
        level={3}
        seoText="Track Analysis: Get detailed info on your tracks using Spotify's audio features. Multiple Playlists: Move or copy your tracks to multiple playlists at once. This is only the beginning: More features like playlist merging and analytics coming soon. "
      />
      <Text className={classes.betaText} mt="md">
        Currently in Beta
      </Text>
      {profile ? (
        <p>
          <Button variant="gradient" size="md" component={Link} to={AppPaths.SelectSourcePlaylist}>
            Let&apos;s go!
          </Button>
        </p>
      ) : (
        <Button
          variant="gradient"
          onClick={initiateLogin}
          size="md"
          leftSection={
            <img src={spotifyIconWhite} alt="Spotify Icon" className={classes.leftIcon} />
          }
        >
          Authorize Spotify to start
        </Button>
      )}
    </Container>
  );
}
