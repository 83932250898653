import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { NotificationData } from '@mantine/notifications';
import { environment } from '../environments/environment';
import { logDebug, logError } from './logError';
import { SpotifyToken } from '../models/spotifyToken.interface';
import { NotificationType } from '../constants/enums';

type OriginalRequest = AxiosError['config'] & {
  doRetry: boolean;
  resolve: (value: AxiosResponse | void) => void;
  reject: (reason?: unknown) => void;
  retryCount: number;
};

// BASE_DELAY × (EXPONENTIAL_MULTIPLIER) ^ (xth_retry) => with 1s and 2 as pf, wait is: 1s (first retry), 2 (2nd retry), 4, 8, 16 seconds
const MAX_RETRIES = 5;
const BASE_DELAY = 1000;
const EXPONENTIAL_MULTIPLIER = 2;

// Singleton implementation to prevent circular dependency
class AxiosService {
  private readonly axiosInstance: AxiosInstance;

  private tokenRefreshHandler?: () => Promise<SpotifyToken | null>;

  private informUser?: (notification: NotificationData & { type: NotificationType }) => void;

  private isUserIdle = false;

  private refreshingToken = false;

  private pendingRequests: OriginalRequest[] = [];

  private tokenRefreshTimeoutId: NodeJS.Timeout | null = null;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: environment.spotifyApiBaseUrl,
    });

    this.axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest: OriginalRequest = error.config;
        const statusCode = error.response?.status;

        if (!(error instanceof AxiosError)) {
          logError('Error in instance but not Axios', error);
          return Promise.reject(error);
        }

        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        if ((statusCode === 401 || statusCode === 403) && !originalRequest.doRetry) {
          return this.refreshTokenAndRetry(originalRequest);
        }

        if (statusCode === 400 || statusCode === 404) {
          return this.handleError('400 or 404 error occurred', error, {
            title: 'Local error occurred',
            message: 'Something unexpected happened. The error has been logged and will be fixed.',
            type: NotificationType.Error,
          });
        }

        if (statusCode === 429) {
          // If rate limit error, use Retry-After header to calculate delay

          // apparently spotify doesn't have their headers set up correctly
          // https://community.spotify.com/t5/Spotify-for-Developers/retry-after-header-not-accessible-in-web-app/td-p/5433144
          // The Spotify API should add the `Access-Control-Expose-Headers: Retry-After` HTTP header to its responses.

          if (!originalRequest.retryCount) {
            originalRequest.retryCount = 0;
          }

          const delay = BASE_DELAY * EXPONENTIAL_MULTIPLIER ** originalRequest.retryCount;

          if (originalRequest.retryCount < MAX_RETRIES) {
            originalRequest.retryCount++;
            logDebug(
              `Rate limit hit. Retrying in ${delay}ms (attempt ${originalRequest.retryCount}/${MAX_RETRIES}).`
            );

            await new Promise((resolve) => {
              setTimeout(resolve, delay);
            });
            return this.axiosInstance(originalRequest);
          }

          logError('Max retries reached for rate-limited request', error);
          return Promise.reject(
            this.handleError('Rate limit exceeded', error, {
              title: 'Rate limit exceeded',
              message: 'Too many requests have been made. Please try again later.',
              type: NotificationType.Warning,
            })
          );
        }

        if (statusCode === 500 || statusCode === 502) {
          return this.handleError('Spotify server error occurred', error, {
            title: 'Server error occurred',
            message:
              'Something unexpected happened on the Spotify servers. The error has been logged.',
            type: NotificationType.Error,
          });
        }

        if (statusCode === 503) {
          return this.handleError('Spotify server error occurred', error, {
            title: 'Server error occurred',
            message: 'The Spotify servers are temporary unavailable, try again later.',
            type: NotificationType.Error,
          });
        }

        return this.handleError('Axios request failed', error, {
          title: 'Unknown error occurred',
          message: 'Something unexpected happened. The error has been logged.',
          type: NotificationType.Error,
        });
      }
    );
  }

  /* eslint-disable no-param-reassign */

  private async refreshTokenAndRetry(originalRequest: OriginalRequest, retryCount = 0) {
    originalRequest.doRetry = true;
    this.pendingRequests.push(originalRequest);

    if (!this.refreshingToken) {
      this.refreshingToken = true;

      try {
        if (this.tokenRefreshHandler) {
          await this.tokenRefreshHandler();
          await this.processPendingRequests();
        } else {
          logError('Token refresh handler is not set');
        }
      } catch (error) {
        if (retryCount < MAX_RETRIES) {
          const retryDelay = retryCount ** EXPONENTIAL_MULTIPLIER * BASE_DELAY;
          setTimeout(() => this.refreshTokenAndRetry(originalRequest, retryCount + 1), retryDelay);
        } else {
          logError('Error while refreshing token', error as Error);
          this.rejectPendingRequests(error as AxiosError);
        }
      } finally {
        this.refreshingToken = false;
      }
    }

    return new Promise((resolve, reject) => {
      // Attach resolve and reject to the request object
      originalRequest.resolve = resolve;
      originalRequest.reject = reject;
    });
  }

  /* eslint-enable no-param-reassign */

  private async processPendingRequests() {
    // eslint-disable-next-line no-restricted-syntax
    for (const pendingRequest of this.pendingRequests) {
      // Introduce a delay between each request
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(resolve, 25);
      });
      this.axiosInstance(pendingRequest).then(pendingRequest.resolve).catch(pendingRequest.reject);
    }

    this.pendingRequests = [];
  }

  private rejectPendingRequests(error: AxiosError) {
    this.pendingRequests.forEach((req) => req.reject(error));
    this.pendingRequests = [];
  }

  private scheduleTokenRefresh(expiresIn: number) {
    // Clear existing timeout to prevent memory leaks
    if (this.tokenRefreshTimeoutId) {
      clearTimeout(this.tokenRefreshTimeoutId);
    }

    const refreshMargin = 300; // 5 minutes before expiry
    const delay = (expiresIn - refreshMargin) * 1000; // in milliseconds

    this.tokenRefreshTimeoutId = setTimeout(() => {
      if (this.tokenRefreshHandler && !this.isUserIdle) {
        this.tokenRefreshHandler()
          .then((newToken) => {
            if (newToken) {
              this.setToken(newToken);
            }
          })
          .catch((error) => {
            logError('Error while scheduled token refresh', error);
          });
      }
    }, delay);
  }

  public setToken(spotifyToken: SpotifyToken) {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${spotifyToken.accessToken}`;
    this.scheduleTokenRefresh(spotifyToken.expiresIn);
  }

  public setTokenRefresh(handleTokenRefresh: () => Promise<SpotifyToken | null>) {
    this.tokenRefreshHandler = handleTokenRefresh;
  }

  public setInformUser(
    informUser: (notification: NotificationData & { type: NotificationType }) => void
  ) {
    this.informUser = informUser;
  }

  public async setUserIsIdle(isIdle: boolean) {
    const wasIdle = this.isUserIdle;
    this.isUserIdle = isIdle;

    if (wasIdle && !isIdle && this.tokenRefreshHandler) {
      await this.tokenRefreshHandler();
    }
  }

  public getInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  private handleError(
    logMessage: string,
    error: AxiosError,
    notification: NotificationData & { type: NotificationType }
  ) {
    logError(logMessage, error);

    if (this.informUser) {
      this.informUser(notification);
    }

    return Promise.reject(error);
  }

  public cleanupAndClear() {
    if (this.tokenRefreshTimeoutId) {
      clearTimeout(this.tokenRefreshTimeoutId);
      this.tokenRefreshTimeoutId = null;
    }
    // Clear the Authorization header (Bearer token)
    if (this.axiosInstance.defaults.headers.common.Authorization) {
      delete this.axiosInstance.defaults.headers.common.Authorization;
    }
    // Reset the token refresh handler
    this.tokenRefreshHandler = undefined;
    this.informUser = undefined;
    this.isUserIdle = false;
    this.refreshingToken = false;
    this.pendingRequests = [];
  }
}

export const axiosService = new AxiosService();
