import * as Sentry from '@sentry/react';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { environment } from '../environments/environment';

export function logError(message: string, error?: Error) {
  if (!environment.production) {
    // eslint-disable-next-line no-console
    console.error(message);
    // eslint-disable-next-line no-console
    console.error(error?.message);
    notifications.show({
      title: 'DEBUG Error',
      message: `${message} - Error Message: ${error?.message}`,
      radius: 'sm',
      color: 'red',
      autoClose: 10000,
    });
  } else if (axios.isAxiosError(error)) {
    Sentry.captureException(error, {
      contexts: {
        axios: {
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
          responseData: error.response?.data,
        },
      },
    });
  } else if (error instanceof Error) {
    // Ensure that error is an instance of Error
    Sentry.captureException(error, {
      contexts: {
        custom: {
          customMessage: message,
          errorName: error.name,
          errorMessage: error.message,
          stack: error.stack,
        },
      },
    });
  } else {
    // Log a fallback message if the error is not an instance of Error
    const fallbackError = new Error(message);
    Sentry.captureException(fallbackError, {
      contexts: {
        custom: {
          customMessage: message,
        },
      },
    });
  }
}

export function logDebug(message: string) {
  if (!environment.production) {
    const messageWithTimeStamp = `${new Date().toLocaleString('nl-NL')}: ${message}`;

    // eslint-disable-next-line no-console
    console.info(messageWithTimeStamp);

    notifications.show({
      title: 'DEBUG Info',
      message: messageWithTimeStamp,
      radius: 'sm',
      color: 'blue',
      autoClose: 5000,
    });
  }
}
