import React from 'react';
import { Flex, Grid, Text, ThemeIcon, Title } from '@mantine/core';
import { type Icon as IconType } from '@tabler/icons-react';
import { iconSize } from '../../../utils/iconSize';
import classes from './miniHero.module.css';

interface ComponentProps {
  header: string;
  text: string;
  icon: IconType;
}

export function MiniHero({ header, text, icon: Icon }: Readonly<ComponentProps>) {
  return (
    <Grid.Col span={{ base: 12, sm: 4 }}>
      <Flex className={classes.flex}>
        <ThemeIcon size={50} radius="md" variant="gradient">
          <Icon style={iconSize(30)} />
        </ThemeIcon>
        <div className={classes.titleAndText}>
          <Title order={5} className={classes.title}>
            {header}
          </Title>
          <Text className={classes.text}>{text}</Text>
        </div>
      </Flex>
    </Grid.Col>
  );
}
