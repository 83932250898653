import { AxiosResponse } from 'axios';
import { Palette } from '@vibrant/color';
import {
  SpotifyAlbumExtended,
  SpotifyAudioFeatures,
  SpotifyTrackItem,
} from '../models/spotifyApi.interface';
import { loadAlbumColors, loadImageAsDataFromURL } from './loadImageAsDataFromURL';
import { axiosService } from './axiosInstance';
import { SpotifyApiPaths } from '../constants/enums';

interface TrackDetailsPromises {
  album: Promise<AxiosResponse<SpotifyAlbumExtended>>;
  isLiked: Promise<AxiosResponse<boolean[]>>;
  imageData?: Promise<string | undefined>;
  colors?: Promise<Palette | undefined>;
  features?: Promise<AxiosResponse<SpotifyAudioFeatures>>;
}

interface TrackDetailsResults {
  album: AxiosResponse<SpotifyAlbumExtended>;
  isLiked: AxiosResponse<boolean[]>;
  imageData?: string;
  colors?: Palette;
  features?: AxiosResponse<SpotifyAudioFeatures>;
}

export async function loadTrackDetailsUtil(track: SpotifyTrackItem): Promise<SpotifyTrackItem> {
  if (track.is_local) {
    return track;
  }

  const trackId = track.track.id;
  const currentTrackAlbumImageUrl = track?.track.album.images?.[0]?.url;
  const isAlbumUrlMissing = !currentTrackAlbumImageUrl;
  const isEpisode = track.track.type === 'episode';

  const axiosInstance = axiosService.getInstance();

  // Define our promise structure
  const promises: TrackDetailsPromises = {
    album: axiosInstance.get<SpotifyAlbumExtended>(track.track.album.href),
    isLiked: axiosInstance.get<boolean[]>(`${SpotifyApiPaths.MyTracks}/contains?ids=${trackId}`),
    ...(isAlbumUrlMissing
      ? {}
      : {
          imageData: loadImageAsDataFromURL(currentTrackAlbumImageUrl).catch(() => undefined),
          colors: loadAlbumColors(currentTrackAlbumImageUrl).catch(() => undefined),
        }),
    ...(isEpisode
      ? {}
      : {
          features: axiosInstance.get<SpotifyAudioFeatures>(
            `${SpotifyApiPaths.AudioFeatures}/${trackId}`
          ),
        }),
  };

  // Await all promises and map to results
  const results = await Promise.all(Object.values(promises));
  const resultMap = Object.fromEntries(
    Object.keys(promises).map((key, index) => [key, results[index]])
  ) as TrackDetailsResults;

  // Construct the track object
  return {
    ...track,
    additionalAlbumInfo: resultMap.album.data,
    liked: resultMap.isLiked.data[0],
    albumImage: isAlbumUrlMissing ? undefined : resultMap.imageData,
    albumColors: isAlbumUrlMissing ? undefined : resultMap.colors,
    ...(resultMap.features && {
      audioFeatures: resultMap.features.data,
    }),
  };
}
