import { Title, TitleOrder } from '@mantine/core';
import classes from './landingPage.module.css';

export function SeoHeading({ seoText, level }: { seoText: string; level: TitleOrder }) {
  return (
    <Title order={level} className={classes.seoHeader}>
      {seoText}
    </Title>
  );
}
