import { Burger, Flex, Text, ThemeIcon } from '@mantine/core';
import * as React from 'react';
import { UserMenu } from './userMenu';
import classes from './headerBar.module.css';
import { PlaylistProIcon } from '../../shared/atoms/playlistProIcon';
import { iconSize } from '../../../utils/iconSize';
import { SeoHeading } from '../../landing/pages/seoHeading';

export default function HeaderBar({
  showBurger,
  opened,
  toggle,
}: Readonly<{
  showBurger: boolean;
  opened?: boolean;
  toggle?: () => void;
}>) {
  return (
    <Flex className={classes.headerBarContainer}>
      <Flex align="center">
        {showBurger ? (
          <Burger
            opened={opened}
            onClick={toggle}
            size="sm"
            aria-label="Toggle navigation"
            hiddenFrom="md"
          />
        ) : null}
        <ThemeIcon size={30} radius="md" m="xs">
          <PlaylistProIcon style={iconSize(24)} />
        </ThemeIcon>

        <Text variant="gradient" className={classes.text}>
          PlaylistPro&nbsp;
        </Text>
        <SeoHeading
          level={1}
          seoText="PlaylistPro: Professional Spotify Playlist Manager & Organizer"
        />
      </Flex>
      <UserMenu />
    </Flex>
  );
}
