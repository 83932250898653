import { StateCreator } from 'zustand/esm';
import type { CombinedSlices } from './combinedStore';

interface BatchTransferTrackState {
  anyTrackSelected: boolean;
  allTracksSelected: boolean;
  clickedSelectAllTracks: boolean;
  selectedTrackIds: Set<string>;
}

export interface BatchTransferTrackSlice extends BatchTransferTrackState {
  toggleCurrentTrackSelected: (trackId: string) => void;
  toggleAllTracksCheckbox: () => void;
  updateSelectedTracksCheckboxState: () => void;
  selectAllTracks: () => void;
  deselectAllTracks: () => void;
}

export const batchTransferTrackSliceInitialState: BatchTransferTrackState = {
  anyTrackSelected: false,
  allTracksSelected: false,
  clickedSelectAllTracks: false,
  selectedTrackIds: new Set(),
};

export const createBatchTransferTrackSlice: StateCreator<
  CombinedSlices,
  [],
  [],
  BatchTransferTrackSlice
> = (set, get) => ({
  ...batchTransferTrackSliceInitialState,

  toggleCurrentTrackSelected(trackId) {
    set((state) => {
      const newSelectedIds = new Set(state.selectedTrackIds);
      if (newSelectedIds.has(trackId)) {
        newSelectedIds.delete(trackId);
      } else {
        newSelectedIds.add(trackId);
      }
      return { ...state, selectedTrackIds: newSelectedIds };
    });

    get().updateSelectedTracksCheckboxState();
  },

  updateSelectedTracksCheckboxState() {
    set((state) => ({
      ...state,
      anyTrackSelected: state.selectedTrackIds.size > 0,
      allTracksSelected: state.sourcePlaylist?.tracks.total === state.selectedTrackIds.size,
    }));
  },

  toggleAllTracksCheckbox() {
    const { anyTrackSelected, allTracksSelected, clickedSelectAllTracks } = get();

    const noTracksSelected = !anyTrackSelected;

    if (clickedSelectAllTracks) {
      get().deselectAllTracks();
    } else {
      if (anyTrackSelected || noTracksSelected) {
        get().selectAllTracks();
      }

      if (allTracksSelected) {
        get().setAllEditablePlaylistsAsNotTarget();
      }
    }

    set({ clickedSelectAllTracks: !clickedSelectAllTracks });

    get().updateSelectedTracksCheckboxState();
  },

  selectAllTracks() {
    set((state) => ({
      ...state,
      selectedTrackIds: new Set(
        state.currentTrackList.filter((track) => !track.is_local).map((track) => track.uniqueId)
      ),
    }));
    get().updateSelectedTracksCheckboxState();
  },

  deselectAllTracks() {
    set({ selectedTrackIds: new Set(), clickedSelectAllTracks: false });
    get().updateSelectedTracksCheckboxState();
  },
});
