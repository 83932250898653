import React, { useState } from 'react';
import { Box, Collapse, Group, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { type Icon as IconType, IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import classes from './navBarLinksGroup.module.css';
import { iconSize } from '../../../utils/iconSize';

interface SubLink {
  label: string;
  link?: string;
  onInvoke?: (navigate?: () => void) => void;
  disabled?: boolean;
}

export interface LinksGroupProps {
  icon: IconType;
  label: string;
  initiallyOpened?: boolean;
  subLinks?: SubLink[];
  link?: string;
}

export function LinksGroup({
  closeParent,
  icon: Icon,
  label,
  initiallyOpened,
  subLinks,
  link,
}: LinksGroupProps & { closeParent: () => void }) {
  const [opened, setOpened] = useState(initiallyOpened ?? false);
  const navigate = useNavigate();

  const hasSubLinks = Array.isArray(subLinks);

  function navigateOnClick(destination: string) {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      navigate(destination);
      closeParent();
    };
  }

  function handleInvokeAction(subLink: SubLink) {
    if (subLink.onInvoke) {
      subLink.onInvoke(subLink.link ? () => navigate(subLink.link as string) : () => {});
      closeParent();
    }
  }

  function onSubLinkClick(event: React.MouseEvent, subLink: SubLink) {
    if (subLink.onInvoke) {
      handleInvokeAction(subLink);
    } else if (subLink.link) {
      navigateOnClick(subLink.link)(event);
    }
  }

  const subLinkItems = (hasSubLinks ? subLinks : []).map((subLink) => (
    <div className={classes.linksGroupContainer} key={subLink.label}>
      <div className={classes.line} />
      <div className={classes.linksGroupContent}>
        <Text
          className={`${classes.link} ${subLink.disabled ? classes.disabledLink : ''}`}
          onClick={(e) => !subLink.disabled && onSubLinkClick(e, subLink)}
          style={{ pointerEvents: subLink.disabled ? 'none' : 'auto' }}
        >
          {subLink.label}
        </Text>
      </div>
    </div>
  ));

  return (
    <>
      <UnstyledButton
        onClick={link ? navigateOnClick(link) : () => setOpened((o) => !o)}
        className={classes.control}
      >
        <Group justify="space-between" gap={0}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon size={30} radius="md">
              <Icon style={iconSize(18)} />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasSubLinks && (
            <IconChevronRight
              className={classes.chevron}
              stroke={1.5}
              style={{
                ...iconSize(16),
                transform: opened ? 'rotate(-90deg)' : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasSubLinks ? <Collapse in={opened}>{subLinkItems}</Collapse> : null}
    </>
  );
}
